import React, { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom'
import Logo from '../Component/Logo';
import LogoMini from '../Component/Logo-mini';
import { AuthContext } from '../App';

const Sidebar = ({ sidebar, isExpanded }) => {
    const { permissiondsa, setPermission, classActive, setClassActive } = useContext(AuthContext)
    const permission =JSON.parse(localStorage.getItem('permissions'))?JSON.parse(localStorage.getItem('permissions')):[]
    const roleName = localStorage.getItem('role') ? localStorage.getItem('role') : ""

    const handleActiveClass = (name) => {
        let data = {
            drp1: false,
            drp2: false,
            drp3: false,
        }
        data[name] = classActive[name] === true ? false : true
        setClassActive(data)
    };
    return (
        <>
            <div className={sidebar ? "sidebar-wrapper active" : "sidebar-wrapper"}>
                <div className="sidebar-header">
                    <div className="d-flex justify-content-between">
                        <div className='sidebar-logo'>
                            <Link to="/Home">
                                <Logo />
                                <LogoMini />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="sidebar-menu">
                    <ul className="menu">
                        <li className="sidebar-item" onClick={() => setClassActive({ ...classActive, drp1: false, drp2: false, drp3: false })} >
                            <NavLink to="/Home" className='sidebar-link'>
                                <i className='bx bxs-home'></i>
                                <span>Dashboard</span>
                            </NavLink>
                        </li>
                        {
                            permission["RC-Details"]?.view == 1 || permission["RC-Count"]?.view == 1 || permission["CarInfo"]?.view == 1 ||
                                permission["Rc-Block"]?.view == 1 || permission["fail_data"]?.view == 1 ||
                                permission["Reminder"]?.view == 1 || permission["Feedback-New"]?.view == 1 || permission["RC-Report"]?.view == 1 ?

                                <li className="sidebar-item has-sub">
                                    <div onClick={() => { setClassActive({ ...classActive, drp3: false, drp2: false }); handleActiveClass("drp1") }} className="sidebar-link">
                                        <i className='bx bxs-credit-card-front' ></i>
                                        <span>RC Information</span>
                                    </div>
                                    <ul className={`${"submenu"} ${classActive.drp1 ? "active" : "inactive"}`} >
                                        {
                                            permission["RC-Details"]?.view == 1 ?
                                                <li className="submenu-item" onClick={() => !isExpanded && setClassActive({ ...classActive, drp1: false, drp2: false, drp3: false })}>
                                                    <NavLink to="/rc-details">
                                                        <span>RC Details</span>
                                                    </NavLink>
                                                </li> : ""
                                        }
                                        {
                                            // permission["RC-Details"]?.view == 1 ?
                                            //     <li className="submenu-item" onClick={() => !isExpanded && setClassActive({ ...classActive, drp1: false, drp2: false, drp3: false })}>
                                            //         <NavLink to="/rc-dashboard">
                                            //             <span>RC Dashboard</span>
                                            //         </NavLink>
                                            //     </li> : ""
                                        }
                                        {
                                            permission["RC-Count"]?.view == 1 ?
                                                <li className="submenu-item" onClick={() => !isExpanded && setClassActive({ ...classActive, drp1: false, drp2: false, drp3: false })}>

                                                    <NavLink to="/rc-count">
                                                        <span>RC Count</span>
                                                    </NavLink>
                                                </li> : ""
                                        }
                                        {
                                            permission["Rc-Block"]?.view == 1 ?
                                                <li className="submenu-item" onClick={() => !isExpanded && setClassActive({ ...classActive, drp1: false, drp2: false, drp3: false })}>

                                                    <NavLink to="/rc-block">
                                                        <span>RC Block</span>
                                                    </NavLink>
                                                </li> : ""
                                        }
                                        {/* {
                                            permission["carinfo"]?.view == 1 ? */}
                                                <li className="submenu-item" onClick={() => !isExpanded && setClassActive({ ...classActive, drp1: false, drp2: false, drp3: false })}>

                                                    <NavLink to="/rc-partner">
                                                        <span>RC Partner</span>
                                                    </NavLink>
                                                </li> 
                                        {/* } */}
                                        {
                                            // permission["Notification-Report"]?.view == 1 ?
                                                <li className="submenu-item" onClick={() => !isExpanded && setClassActive({ ...classActive, drp1: false, drp2: false, drp3: false })}>
                                                    <NavLink to="/faildata">
                                                        <span>RC Fail Data</span>
                                                    </NavLink>
                                                </li>
                                                // : ""
                                        }
                                        {
                                            // permission["Notification-Report"]?.view == 1 ?
                                                <li className="submenu-item" onClick={() => !isExpanded && setClassActive({ ...classActive, drp1: false, drp2: false, drp3: false })}>
                                                    <NavLink to="/rc-services">
                                                        <span>RC Services</span>
                                                    </NavLink>
                                                </li>
                                                // : ""
                                        }

                                        {
                                            // permission["Reminder"]?.view == 1 ?
                                            //     <li className="submenu-item" onClick={() => !isExpanded && setClassActive({ ...classActive, drp1: false, drp2: false, drp3: false })}>
                                            //         <NavLink to="/reminder">
                                            //             <span>RC Reminder</span>
                                            //         </NavLink>
                                            //     </li> : ""
                                        }
                                        {
                                            // permission["Feedback-New"]?.view == 1 ?
                                            //     <li className="submenu-item" onClick={() => !isExpanded && setClassActive({ ...classActive, drp1: false, drp2: false, drp3: false })}>
                                            //         <NavLink to="/feedback">
                                            //             <span>Feedback</span>
                                            //         </NavLink>
                                            //     </li> : ""
                                        }
                                        {
                                            // permission["Notification-Report"]?.view == 1 ?
                                            //     <li className="submenu-item" onClick={() => !isExpanded && setClassActive({ ...classActive, drp1: false, drp2: false, drp3: false })}>
                                            //         <NavLink to="/notification-report">
                                            //             <span>Notification Report</span>
                                            //         </NavLink>
                                            //     </li> : ""
                                        }
                                        
                                    </ul>
                                </li>
                                : ""
                        }
                        {
                            permission["License-info"]?.view == 1 ?
                                <li className="sidebar-item" onClick={() => setClassActive({ ...classActive, drp1: false, drp2: false, drp3: false })}>
                                    <NavLink to="/license-information" className='sidebar-link'>
                                        <i className='bx bxs-id-card' ></i>
                                        <span>DL Information</span>
                                    </NavLink>
                                </li> : ""
                        }
                        {
                            permission["Challan-info"]?.view == 1 ?
                                <li className="sidebar-item" onClick={() => setClassActive({ ...classActive, drp1: false, drp2: false, drp3: false })}>
                                    <NavLink to="/challan-information" className='sidebar-link'>
                                        <i className='bx bxs-credit-card' ></i>
                                        <span>Challan Information</span>
                                    </NavLink>
                                </li> : ""
                        }
                        {
                            // permission["License-info"]?.view == 1 ?
                            //     <li className="sidebar-item" onClick={() => setClassActive({ ...classActive, drp1: false, drp2: false, drp3: false })}>
                            //         <NavLink to="/token" className='sidebar-link'>
                            //             <i className='bx bxs-receipt' ></i>
                            //             <span>Token</span>
                            //         </NavLink>
                            //     </li> : ""
                        }

                        {
                            permission["App-Update"]?.view == 1 || permission["Authorization"]?.view == 1 || permission["API-Priority"]?.view == 1 ?
                                <li className="sidebar-item has-sub" >
                                    <div onClick={() => { setClassActive({ ...classActive, drp1: false, drp3: false }); handleActiveClass("drp2") }} className="sidebar-link">
                                        <i className='bx bxs-check-circle'></i>
                                        <span>API</span>
                                    </div>
                                    <ul className={`${"submenu"} ${classActive.drp2 ? "active" : "inactive"}`} >
                                        {
                                            permission["App-Update"]?.view == 1 ?
                                                <li className="submenu-item" onClick={() => !isExpanded && setClassActive({ ...classActive, drp1: false, drp2: false, drp3: false })}>

                                                    <NavLink to="/app-update">
                                                        <span>App Update</span>
                                                    </NavLink>
                                                </li> : ""
                                        }
                                        {
                                            // permission["Authorization"]?.view == 1 ?
                                                // <li className="submenu-item" onClick={() => !isExpanded && setClassActive({ ...classActive, drp1: false, drp2: false, drp3: false })}>

                                                //     <NavLink to="/authorization">
                                                //         <span>Authorization</span>
                                                //     </NavLink>
                                                // </li> : ""
                                        }
                                        {
                                            permission["API-Priority"]?.view == 1 ?
                                                <li className="submenu-item" onClick={() => !isExpanded && setClassActive({ ...classActive, drp1: false, drp2: false, drp3: false })}>

                                                    <NavLink to="/api-priority">
                                                        <span>API Priority</span>
                                                    </NavLink>
                                                </li> : ""
                                        }
                                        {/* {
                                            permission["API-Priority"]?.view == 1 ?
                                                <li className="submenu-item" onClick={() => !isExpanded && setClassActive({ ...classActive, drp1: false, drp2: false, drp3: false })}>

                                                    <NavLink to="/api-client-priority">
                                                        <span>API Client Priority</span>
                                                    </NavLink>
                                                </li> : ""
                                        } */}
                                        {/* {
                                            permission["API-Priority"]?.view == 1 ?
                                                <li className="submenu-item" onClick={() => !isExpanded && setClassActive({ ...classActive, drp1: false, drp2: false, drp3: false })}>

                                                    <NavLink to="/api-purchase-priority">
                                                        <span>API Purchase Priority</span>
                                                    </NavLink>
                                                </li> : ""
                                        } */}
                                    </ul>
                                </li>
                                : ""
                        }
                        {
                            permission["API-Lead"]?.view == 1 ?
                                <li className="sidebar-item" onClick={() => setClassActive({ ...classActive, drp1: false, drp2: false, drp3: false })}>
                                    <NavLink to="/api-lead" className='sidebar-link'>
                                        <i className='bx bxs-id-card' ></i>
                                        <span>API Lead</span>
                                    </NavLink>
                                </li> : ""
                        }
                        {
                            permission["Quotes"]?.view == 1 ?
                                <li className="sidebar-item" onClick={() => setClassActive({ ...classActive, drp1: false, drp2: false, drp3: false })}>
                                    <NavLink to="/quotes" className='sidebar-link'>
                                        <i className='bx bxs-quote-alt-left'></i>
                                        <span>Quotes</span>
                                    </NavLink>
                                </li> : ""
                        }
                        {
                            roleName=="ADMIN" ?
                                <li className="sidebar-item has-sub">
                                    <div onClick={() => { setClassActive({ ...classActive, drp1: false, drp2: false }); handleActiveClass("drp3") }} className="sidebar-link">
                                        <i className='bx bxs-lock-open-alt'></i>
                                        <span>User Permission</span>
                                    </div>
                                    <ul className={`${"submenu"} ${classActive.drp3 ? "active" : "inactive"}`} >

                                        <li className="submenu-item" onClick={() => !isExpanded && setClassActive({ ...classActive, drp1: false, drp2: false, drp3: false })}>

                                            <NavLink to="/module">
                                                <span>Module</span>
                                            </NavLink>
                                        </li>
                                        <li className="submenu-item" onClick={() => !isExpanded && setClassActive({ ...classActive, drp1: false, drp2: false, drp3: false })}>

                                            <NavLink to="/role">
                                                <span>Role</span>
                                            </NavLink>
                                        </li>
                                        <li className="submenu-item" onClick={() => !isExpanded && setClassActive({ ...classActive, drp1: false, drp2: false, drp3: false })}>

                                            <NavLink to="/user">
                                                <span>Permission</span>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>

                                : ""
                        }
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Sidebar