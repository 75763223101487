import dayjs from "dayjs";
import Cookies from "js-cookie";
import Pagination from "rc-pagination";
import React, { useEffect, useState } from "react";
import { Button, Card, Form, InputGroup, Modal, Table } from "react-bootstrap";
import { SelectPicker } from "rsuite";
import { API, APIS } from "../../App";
import Layout from '../../layout/Layout';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from "moment";

var Page_array = [];
var State_array = [];
var Source_array = [];
const RcDetails = () => {
    const token = Cookies.get("fmljwt");
    const [Data, setData] = useState([])
    const [size, setSize] = useState(100);
    const [current, setCurrent] = useState(1);
    const [PageHook, setPageHook] = useState([])
    const [State_Data, setState_Data] = useState([])
    const [Source, setSource] = useState([])
    const [show, setShow] = useState(false);
    const [loading, setIsLoading] = useState(true)
    const [View_Data, setView_Data] = useState()
    const [Query, setQuery] = useState({
        collectionNames: "GJ",
        searchvalue: "",
        source: "",
    });
    const [searchReg, setSearchReg] = useState('')
    const isValidValue = value => value !== null && value !== "NA" && !String(value)?.includes("XXX") && value !== "" && value != 0;
    const displayKeys = {
        id: "ID",
        owner_name: "Owner Name",
        father_name: "Father Name",
        owner_sr_no: "Owner Sr. No",
        address: "Address",
        mobile_no: "Mobile No",
        chasi_no: "Chasis No",
        engine_no: "Engine No",
        rto: "RTO Name",
        reg_no: "Registration No",
        regn_dt: "Registration Date",
        no_of_seats: "No Of Seats",
        fuel_type: "Fuel Type",
        fuel_norms: "Fuel Norms",
        maker: "Maker",
        vehicle_age: "Vehicle Age",
        vehicle_color: "Vehicle Color",
        body_type_desc: "Body Type Desc",
        state: "State",
        financer_details: "Financer Details",
        policy_no: "Policy No",
        insurance_comp: "Insurance Company",
        insUpto: "Insurance Upto",
        puc_no: "Puc No",
        puc_upto: "Puc Upto",
        maker_modal: "Maker Model",
        fitness_upto: "Fitness Upto",
        source: "Source",
        manufacturer_month_yr: "Manufacturer Month/Year",
        gvw: "GVW",
        cubic_cap: "Cubic Cap",
        sleeper_cap: "Sleeper Cap",
        stand_cap: "Stand Cap",
        wheelbase: "Wheelbase",
        permit_no: "Permit No",
        permit_issue_date: "Permit Issue Date",
        permit_from: "Permit From",
        permit_upto: "Permit Upto",
        permit_type: "Permit Type",
        rc_np_no: "RC NP no",
        rc_np_upto: "RC NP Upto",
        rc_np_issued_by: "RC NP Issued By",
        rc_unld_wt: "RC Unld Wt",
        blacklist_status: "Blacklist Status",
        noc_details: "NOC Details",
        tax_upto: "Tax Upto",
        status: "Status",
    };
    const GetData = async () => {
        setIsLoading(true);
        try {
            const Form = new FormData()
            Form.append("state", Query.collectionNames)
            Form.append("searchData", Query.searchvalue)
            Form.append("source", Query.source)
            Form.append("page", current)
            Form.append("limit", size)
            const result = await APIS.post("/rcdetailsearch", Form, { headers: { authorization: `${token}` } })
            setData(result?.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }
    useEffect(() => {
        GetData()
    }, [Query, size, current])
    useEffect(() => {
        PageGetData()
        stateGetData()
        sourceGetData()
    }, [])

    const paginationData = (page, pageSize) => {
        setCurrent(page);
        setSize(pageSize);
    };


    const PageGetData = async () => {
        var PageNumber = [100, 200, 500, 1000]
        Page_array = []
        PageNumber?.map((val, index) => {
            Page_array.push({ label: val, value: val })
        })
        setPageHook(Page_array)
    };

    const stateGetData = async () => {
        var state = ['AN', 'AP', 'AR', 'AS', 'BR', 'CH', 'CG', 'DD', 'DL', 'GA', 'GJ', 'HR', 'HP', 'JK', 'JH', 'KA', 'KL', 'LA', 'LD', 'MP', 'MH', 'MN', 'ML', 'MZ', 'NL', 'OD', 'PY', 'PB', 'RJ', 'SK', 'TN', 'TS', 'TR', 'UP', 'UK', 'WB']
        State_array = []
        state?.map((val, index) => {
            State_array.push({ label: val, value: val })
        })
        setState_Data(State_array)
    };

    const sourceGetData = async () => {
        const sourceData = await APIS.post("/sourceInfo", {}, { headers: { authorization: `${token}` } })
        const totalCount = sourceData?.data?.data?.reduce((total, item) => {
            return total + parseInt(item.count);
        }, 0);
        Source_array = []
        Source_array.push({ label: `All Source (${totalCount ? totalCount : 0})`, value: "" })
        sourceData?.data?.data?.map((val, index) => {
            Source_array.push({ label: `${val.third_party_api} (${val.count})`, value: val.third_party_api })
        })
        setSource(Source_array)
    };

    const QueryHendler = async (e, name) => {
        if (name === "searchvalue") {
            setQuery({ ...Query, [e.target.name]: e.target.value });
        } else if (name === "") {
            GetData()
        } else {
            setQuery({ ...Query, [name]: e })
        }
    }

    const handleShow = async (id) => {
        setIsLoading(true);
        try {
            setShow(true)
            const Form = new FormData()
            Form.append('id', id)
            Form.append('state', Query.collectionNames)
            const result = await APIS.post("/rcdetailsearch", Form, { headers: { authorization: `${token}` } })
            setView_Data(result?.data?.data[0])
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }
    const handleClose = () => setShow(false);

    const handleSearch = () => {
        setQuery({ ...Query, searchvalue: searchReg })
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent the default form submission behavior
            handleSearch()
        }
    };

    const fpOptions = ['Full', 'Partial'].map(
        item => ({ label: item, value: item })
    );
    const dateOptions = ['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Yearly'].map(
        item => ({ label: item, value: item })
    );

    const [dateRanges, setdateRanges] = useState({
        start: moment().subtract(29, 'days'),
        end: moment(),
    });
    const { start, end } = dateRanges;

    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3 className="my-1">RC Details <span>123</span></h3>
                <div className="page-heading-right">
                    <div className="inputgroup my-1">
                        <Form.Control
                            type="text"
                            name="searchvalue"
                            placeholder="Search by RC | Engine | Chassis No..."
                            className="wv-325 ms-3"
                            onChange={(e) => { e.target?.value == "" && setQuery({ ...Query, searchvalue: "" }); setSearchReg(e.target.value) }}
                            onKeyPress={handleKeyPress}
                        />
                        <i className="bx bx-search" onClick={handleSearch} />
                    </div>

                    <SelectPicker
                        cleanable={false}
                        data={State_Data}
                        searchable={false}
                        defaultValue={"GJ"}
                        className="wv-100 my-1 ms-3"
                        placeholder="Select State"
                        onChange={(e) => { QueryHendler(e, "collectionNames"); setCurrent(1); }}
                    />

                    <SelectPicker
                        cleanable={false}
                        data={fpOptions}
                        searchable={false}
                        defaultValue={""}
                        placeholder="Select Full/Partial"
                        className="wv-150 my-1 ms-3"
                    />
                    <SelectPicker
                        cleanable={false}
                        data={Source}
                        searchable={false}
                        defaultValue={""}
                        placement="bottomEnd"
                        className="wv-150 my-1 ms-3"
                        placeholder="Select Source"
                        onChange={(e) => { QueryHendler(e, "source"); setCurrent(1); }}
                    />

                    <SelectPicker
                        cleanable={false}
                        data={dateOptions}
                        searchable={false}
                        defaultValue={"Daily"}
                        className="wv-100 my-1 ms-3"
                        placeholder="Select Date"
                    />

                    <DateRangePicker initialSettings={{
                        startDate: start.toDate(),
                        endDate: end.toDate(),
                        ranges: {
                            Today: [moment().toDate(), moment().toDate()],
                            Yesterday: [
                                moment().subtract(1, 'days').toDate(),
                                moment().subtract(1, 'days').toDate(),
                            ],
                            'Last 7 Days': [
                                moment().subtract(6, 'days').toDate(),
                                moment().toDate(),
                            ],
                            'Last 30 Days': [
                                moment().subtract(29, 'days').toDate(),
                                moment().toDate(),
                            ],
                            'This Month': [
                                moment().startOf('month').toDate(),
                                moment().endOf('month').toDate(),
                            ],
                            'Last Month': [
                                moment().subtract(1, 'month').startOf('month').toDate(),
                                moment().subtract(1, 'month').endOf('month').toDate(),
                            ],
                        },
                    }}>
                        <input type="text" name="date" placeholder="Select Date" className="form-control wv-225 my-1 ms-3 " />
                    </DateRangePicker>

                    <SelectPicker
                        cleanable={false}
                        data={PageHook}
                        searchable={false}
                        value={size}
                        className="wv-75 my-1 ms-3"
                        onChange={(e) => {
                            setSize(Number(e));
                            setCurrent(1);
                        }}
                    />

                </div>
            </div>
            <div className="page-content">
                <Card className="radiustop-0">
                    <Card.Body>
                        {loading && <div className="loader table-loader"></div>}
                        <Table className="table-bg-primary" bordered responsive striped>
                            <thead>
                                <tr>
                                    <th width="5%" className="text-center">No</th>
                                    <th width="10%" className='text-center'>Registration No</th>
                                    <th width="21%">Owner Name</th>
                                    <th width="17%" className='text-center'>Chassis No</th>
                                    <th width="17%" className='text-center'>Engine No</th>
                                    <th width="10%" className='text-center'>Mobile Number</th>
                                    <th width="10%" className='text-center'>Date</th>
                                    {/* <th width="8%" className='text-center'>Update Date</th> */}
                                    <th width="10%" className='text-center'>Source</th>
                                    <th width="10%" className='text-center'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Data?.data?.length > 0 ? Data?.data?.map((val, i) => {
                                        return (
                                            <tr key={i}>
                                                <td className='text-center'>{(current === 1) ? i + 1 : current * size + i + 1 - size}</td>
                                                <td className='text-center'>{val.reg_no}</td>
                                                <td>{val.owner_name}</td>
                                                <td className='text-center'>{val.chasi_no}</td>
                                                <td className='text-center'>{val.engine_no}</td>
                                                <td className='text-center'>{val?.mobile_no == "0" || val?.mobile_no == "NA" || !val?.mobile_no ? "-" : val?.mobile_no}</td>
                                                <td className='text-center'>{val?.updated_at ? moment(val?.updated_at).format('DD-MM-YYYY') : moment(val?.created_at).format("DD-MM-YYYY")}</td>
                                                {/* <td className='text-center'>{val?.updated_at ? moment(val?.updated_at).format('DD-MM-YYYY') : "-"}</td> */}
                                                {/* <td className='text-center'>{val?.created_at ? moment.utc(val?.created_at).format("DD-MM-YYYY") : "-"}</td>
                                                <td className='text-center'>{val?.updated_at ? moment.utc(val?.updated_at).format('DD-MM-YYYY') : "-"}</td> */}
                                                <td className='text-center'>{val.source}</td>
                                                <td className='text-center'>
                                                    <Button variant="primary" className="btn btn-icon btn-transparent" onClick={() => handleShow(val.id)}>
                                                        <i className="bx bx-show"></i>
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    }) : <tr>
                                        <td colSpan="100%" className="p-0">
                                            <div className='no-found'>
                                                <img src="../../not-found/image.svg" />
                                                <p>No Found RC Details</p>
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                        {Data?.totalcount?.totalcount > size ? (
                            <div className="pagination-custom">
                                <Pagination
                                    showTitle={false}
                                    className="pagination-data"
                                    onChange={paginationData}
                                    total={Data?.totalcount?.totalcount}
                                    current={current}
                                    pageSize={size}
                                    showSizeChanger={false}
                                />
                            </div>
                        ) : (
                            ""
                        )}
                    </Card.Body>
                </Card>
            </div>
            <Modal
                show={show} onHide={handleClose}
                size="lg"
                aria-labelledby="user-details"
                className="user-details-modal"
                scrollable
            >
                <Modal.Header closeButton>
                    <Modal.Title id="user-details">
                        User Details - <span className="text-primary">{View_Data?.reg_no}</span>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {Object.entries(displayKeys).map(([key, displayName]) => {
                        const value = View_Data && View_Data[key];
                        if (isValidValue(value)) {
                            return (
                                <div className="user-details" key={key}>
                                    <p>{displayName}</p>
                                    <span>{value}</span>
                                </div>
                            );
                        }
                        return null;
                    })}
                </Modal.Body>
            </Modal>
        </Layout>
    )
}

export default RcDetails