import dayjs from "dayjs";
import Cookies from "js-cookie";
import Pagination from "rc-pagination";
import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Form, InputGroup, Modal, Table } from "react-bootstrap";
import { SelectPicker } from "rsuite";
import Layout from '../../layout/Layout';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from "moment";
import axios from "axios";

var Page_array = [50, 100, 200, 500, 1000].map((val) => {
    return { label: val, value: val }
})
const apiLeadStatus = [
    { value: "", label: "All Status" },
    { value: 1, label: "Pending" },
    { value: 2, label: "Running" },
    { value: 3, label: "Close" },
];
const statusOptions = [
    { value: 0, label: "Pending" },
    { value: 1, label: "Running" },
    { value: 2, label: "Close" }
];
const apiType = [
    { value: "", label: "All Type" },
    { value: "Vehicle RC Verification API", label: "Vehicle RC Verification API" },
    { value: "Challan Details API", label: "Challan Details API" },
    { value: "Driving Licence API", label: "Driving Licence API" },
    { value: "Reverse RC - Vehicle details by Chassis No", label: "Reverse RC - Vehicle details by Chassis No" },
    { value: "Reverse RC - Vehicle details by Engine No", label: "Reverse RC - Vehicle details by Engine No" },
    { value: "Engine & Chassis No by Vehicle RC No", label: "Engine & Chassis No by Vehicle RC No" },
    { value: "Api Services", label: "Api Services" }
]

const ApiLead = () => {
    const token = Cookies.get("fmljwt");
    const userPermission = JSON.parse(localStorage.getItem('permissions'))
    const [Data, setData] = useState([])
    const [size, setSize] = useState(50);
    const [current, setCurrent] = useState(1);
    const [loading, setIsLoading] = useState(true)
    const [Query, setQuery] = useState({
        type: "",
        status: "",
        searchvalue: ""
    });
    const [dateRanges, setdateRanges] = useState({
        start: moment().subtract(7, 'days'),
        end: moment(),
    });
    const handleCallback = (start, end) => {
        setdateRanges({ start, end });
    };
    const [activeItemId, setActiveItemId] = useState(null);

    const { start, end } = dateRanges;
    const [searchReg, setSearchReg] = useState('')
    const [isEditing, setEditing] = useState(false);
    const [editedContent, setEditedContent] = useState('');
    const [modalMessage, setModalMessage] = useState({});


    const [apishow, setAPIShow] = useState(false);

    const handleAPIShow = (message, type, id) => {
        if (!message) {
            setEditing(true)
        }
        console.log('message', message)
        setEditedContent(message)
        setModalMessage({ content: message, type: type, id: id }); // Set the full message
        setAPIShow(true); // Open the modal
    };

    const handleAPIClose = () => {
        setEditing(false)
        setEditedContent('')
        setAPIShow(false);
    };

    const truncateMessage = (message, type, id = null, maxLength = 40) => {
        if (message.length > maxLength) {
            return (
                <>
                    {message.substring(0, maxLength)}... <u><span className="text-primary cursor-pointer " onClick={() => handleAPIShow(message, type, id)}>View More</span></u>

                </>
            );
        }
        return message;
    };


    const GetData = async () => {
        setIsLoading(true);
        try {
            const filter = {
                page: current,
                limit: size,
                startDate: moment(start).format("YYYY-MM-DD"),
                endDate: moment(end).format("YYYY-MM-DD"),
                status: Query?.status,
                search: Query?.searchvalue?.trim(),
                api_type: Query?.type
            };

            const result = await axios.post("https://websiteapi.vehicleinfo.app/web/get-in-touch-data", filter, { headers: { authorization: `${token}` } })
            setData(result?.data)
            console.log('first', result)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }
    useEffect(() => {
        GetData()
    }, [Query, size, current, dateRanges])


    const paginationData = (page, pageSize) => {
        setCurrent(page);
        setSize(pageSize);
    };




    const QueryHendler = async (e, name) => {
        if (name === "searchvalue") {
            setQuery({ ...Query, [e.target.name]: e.target.value });
        } else if (name === "") {
            GetData()
        } else {
            setQuery({ ...Query, [name]: e })
        }
    }


    const handleSearch = () => {
        setQuery({ ...Query, searchvalue: searchReg })
    }

    const handleKeyPress = (e) => {
        setCurrent(1)
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent the default form submission behavior
            handleSearch()
        }
    };

    const handleDoubleClick = (id) => {
        setActiveItemId(id === activeItemId ? null : id);
    };

    const hadleOnChange = async (value, id) => {
        console.log('value', value)
        const updateData = {
            id: id,
            status: `${value}`
        }
        try {
            const result = await axios.post("https://websiteapi.vehicleinfo.app/web/get-in-touch-update", updateData, { headers: { authorization: `${token}` } })
            if (result?.status) {
                GetData()
            }
        } catch (error) {
            console.log(error)
        }
        setActiveItemId(null);
    }

    const handleSaveClick = async (e) => {
        e.preventDefault();
        if (modalMessage.content && !isEditing) {
            setEditing(true)
        } else {
            const updateData = {
                id: modalMessage.id,
                note: editedContent
            }
            const result = await axios.post("https://websiteapi.vehicleinfo.app/web/get-in-touch-update", updateData, { headers: { authorization: `${token}` } })

            setEditing(false)
            setAPIShow(false)
            setEditedContent('')
            GetData();
        }

    };

    const deleteApiLead = async (id) => {
        if (window.confirm("Are you sure you want to delete this record?")) {
            try {
                const result = await axios.post("https://websiteapi.vehicleinfo.app/web/get-in-touch-delete", { id: id }, { headers: { authorization: `${token}` } })
                if (result?.status) {
                    GetData()
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3 className="my-1">API Lead <span>{`(${Data?.total})`}</span></h3>
                <div className="page-heading-right">
                    <div className="inputgroup my-1">
                        <Form.Control
                            type="text"
                            name="searchvalue"
                            placeholder="Search by email or phone number"
                            className="wv-400 ms-3"
                            onChange={(e) => { e.target?.value == "" && setQuery({ ...Query, searchvalue: "" }); setSearchReg(e.target.value) }}
                            onKeyPress={handleKeyPress}
                        />
                        <i className="bx bx-search" onClick={handleSearch} />
                    </div>


                    <SelectPicker
                        cleanable={false}
                        data={apiType}
                        searchable={false}
                        defaultValue={""}
                        placement="bottomEnd"
                        className="wv-150 my-1 ms-3"
                        placeholder="Select Type"
                        onChange={(e) => { QueryHendler(e, "type"); setCurrent(1); }}
                    />

                    <SelectPicker
                        cleanable={false}
                        data={apiLeadStatus}
                        searchable={false}
                        defaultValue={""}
                        className="wv-150 my-1 ms-3"
                        placeholder="Select Status"
                        onChange={(e) => { QueryHendler(e, "status"); setCurrent(1); }}

                    />

                    <DateRangePicker initialSettings={{
                        startDate: start.toDate(),
                        endDate: end.toDate(),
                        ranges: {
                            Today: [moment().toDate(), moment().toDate()],
                            Yesterday: [
                                moment().subtract(1, 'days').toDate(),
                                moment().subtract(1, 'days').toDate(),
                            ],
                            'Last 7 Days': [
                                moment().subtract(6, 'days').toDate(),
                                moment().toDate(),
                            ],
                            'Last 30 Days': [
                                moment().subtract(29, 'days').toDate(),
                                moment().toDate(),
                            ],
                            'This Month': [
                                moment().startOf('month').toDate(),
                                moment().endOf('month').toDate(),
                            ],
                            'Last Month': [
                                moment().subtract(1, 'month').startOf('month').toDate(),
                                moment().subtract(1, 'month').endOf('month').toDate(),
                            ],
                        },
                    }}
                        onCallback={handleCallback}>
                        <input type="text" name="date" value={dateRanges?.start.format('DD/MM/YYYY') + ' - ' + dateRanges?.end.format('DD/MM/YYYY')} placeholder="Select Date" className="form-control wv-225 my-1 ms-3 " />
                    </DateRangePicker>

                    <SelectPicker
                        cleanable={false}
                        data={Page_array}
                        searchable={false}
                        value={size}
                        className="wv-75 my-1 ms-3"
                        onChange={(e) => {
                            setSize(Number(e));
                            setCurrent(1);
                        }}
                    />

                </div>
            </div>
            <div className="page-content">
                <Card className="radiustop-0">
                    <Card.Body>
                        {loading && <div className="loader table-loader"></div>}
                        <Table className="table-bg-primary" bordered responsive striped>
                            <thead>
                                <tr>
                                    <th width="5%" className="text-center">No</th>
                                    <th width="10%" className='text-center'>Date</th>
                                    <th width="15%" className='text-center'>Contact Information</th>
                                    <th width="10%" className='text-center'>Call per month</th>
                                    <th width="10%" className='text-center'>Status</th>
                                    <th width="5%" className='text-center'>Message</th>
                                    <th width="30%" className='text-center'>Note</th>
                                    {userPermission["API-Lead"]?.delete == 1 &&
                                        <th width="5%" className='text-center'>Action</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Data?.data?.length > 0 ? Data?.data?.map((val, i) => {
                                        return (
                                            <tr key={i}>
                                                <td className='text-center'>{(current === 1) ? i + 1 : current * size + i + 1 - size}</td>
                                                <td className='text-center'>{val?.created_at ? dayjs(val?.created_at).format('DD-MM-YYYY') : null}</td>
                                                <td ><div className="col-w-fix">
                                                    {val.name}
                                                    <br />
                                                    {val.phone}
                                                    <br />
                                                    <div style={{ color: 'blue' }}>{val.email}</div>
                                                </div></td>
                                                <td className='text-center'>{val.call_per_month}</td>
                                                <td className='text-center'>
                                                    <div className="custom-select-menu" onDoubleClick={() => (userPermission["API-Lead"]?.add == 1 || userPermission["API-Lead"]?.edit == 1) && handleDoubleClick(val?.id)} >
                                                        <SelectPicker
                                                            data={statusOptions}
                                                            appearance="subtle"
                                                            cleanable={false}
                                                            searchable={false}
                                                            open={activeItemId == val?.id}
                                                            onChange={(values) => hadleOnChange(values, val.id)}
                                                            onExited={() => setActiveItemId(null)}
                                                            value={Number(val?.status)}
                                                            placeholder="Subtle"
                                                            readOnly={userPermission["API-Lead"]?.add != 1 && userPermission["API-Lead"]?.edit != 1}
                                                            className={`${(val?.status == 0) ? "pending" : (val?.status == 1) ? "running" : "close"}`}
                                                        />
                                                    </div>
                                                    {/* <div className="status-container" onDoubleClick={() => handleDoubleClick(val?.id)}  >
                                                        <ReactSelect
                                                            options={statusOptions}
                                                            onChange={(values) => hadleOnChange(values, val?.id)}
                                                            styles={customStyles}
                                                            menuIsOpen={activeItemId == val?.id}
                                                            value={defaultStatus(val?.status)}
                                                            classNamePrefix="color-select-picker"
                                                            // 0 means pending 1 means Running and 2 means close
                                                            className={`custom-select-menu ${(val?.status === 0 || val?.status === "0") ? "pending" : (val?.status === 1 || val?.status === "1") ? "Running" : "close"} cursor-pointer`}
                                                        />
                                                    </div> */}
                                                </td>
                                                <td className='text-center'>
                                                    <Button variant="outline-warning" size="sm" className="btn-icon" onClick={() => handleAPIShow(val?.message, "message")} ><i className='bx bx-show'></i></Button>
                                                    {/* <div>
                                                        {val?.message ? <div size="2em" onClick={() => handleMoreClick(val?.message, "message")} style={{ cursor: 'pointer', color: "A9B2BD" }} /> : '-'}
                                                    </div> */}
                                                </td>
                                                <td className=''>
                                                    <div onDoubleClick={() => handleAPIShow(val?.note, "note", val?.id)} className="col-w-fix cursor-pointer">
                                                        <p className="mb-0">{val?.note ? truncateMessage(val?.note, "note", val?.id, 170) : val?.type}</p>
                                                    </div>
                                                </td>

                                                {userPermission["API-Lead"]?.delete == 1 &&
                                                    <td className='text-center'>
                                                        <Button variant="outline-danger" size="sm" className="btn-icon" onClick={() => deleteApiLead(val.id)} ><i className='bx bx-trash-alt' ></i></Button>
                                                    </td>
                                                }
                                            </tr>
                                        )
                                    }) : <tr>
                                        <td colSpan="100%" className="p-0">
                                            <div className='no-found'>
                                                <img src="../../not-found/image.svg" />
                                                <p>API Lead Recoard Not Found </p>
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                        {Data?.total > size ? (
                            <div className="pagination-custom">
                                <Pagination
                                    showTitle={false}
                                    className="pagination-data"
                                    onChange={paginationData}
                                    total={Data?.total}
                                    current={current}
                                    pageSize={size}
                                    showSizeChanger={false}
                                />
                            </div>
                        ) : (
                            ""
                        )}
                    </Card.Body>
                </Card>
            </div>
            <Modal
                show={apishow} onHide={handleAPIClose}
                size="md"
                className="user-details-modal"
                scrollable
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {modalMessage.type == "message" ? "Message" : "Note"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control
                        as="textarea"
                        placeholder="Message here..."
                        value={editedContent}
                        onChange={(e) => setEditedContent(e.target.value)}
                        rows={5}
                        disabled={!isEditing}
                    />
                </Modal.Body>
                {modalMessage.type !== "message" && <Modal.Footer>
                    <Button variant="secondary" onClick={handleAPIClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSaveClick} disabled={userPermission["API-Lead"]?.add != 1 && userPermission["API-Lead"]?.edit != 1}>
                        {(modalMessage.content && !isEditing) ? "Edit" : "Save"}
                    </Button>
                </Modal.Footer>}
            </Modal>
        </Layout>
    )
}

export default ApiLead